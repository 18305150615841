import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BreadCrumbs from '../components/common/breadcrumbs'

const Tags = ({ data }) => {

  const breadcrumbs = {
    'Home': '/',
    'Blog': '/blog',
    [data.taxonomyTermTags.name]: null
  }

  return (
    <Layout pageTitle={data.taxonomyTermTags.name}>
      <SEO title={data.taxonomyTermTags.name} />
      <div className="page-tags">
        <div className="container is-desktop">
          <div className="page-breadcrumbs notification">
            <BreadCrumbs input={breadcrumbs} />
          </div>
          {
            data.allNodeBlogPost.edges.map(node => {
              return (
                <div className="teaser-blog" key={node.node.id} style={{ paddingBottom: `60px` }}>
                  <div className="teaser-title" style={{ width: `80%`, marginBottom: `20px` }}>
                    <Link to={node.node.path.alias} style={{ fontSize: `32px`, fontWeight: `900` }}>
                      {node.node.title}
                    </Link>
                  </div>
                  <div className="teaser-content columns">
                    <div className="teaser-image column is-one-third">
                      <Img fixed={node.node.relationships.field_image.localFile.childImageSharp.fixed} />
                    </div>
                    <div className="teaser-blog column">
                      <div className="blog-contents" dangerouslySetInnerHTML={{ __html: node.node.body.summary }}></div>
                      <div className="blog-actions">
                        <Link to={node.node.path.alias} style={{ textDecoration: `underline`, fontWeight: `bold` }}>
                          Read More
                        </Link>
                        <Link to="/blog" style={{ textDecoration: `underline`, fontWeight: `bold`, marginLeft: `10px` }}>
                          blogger's Blog
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}
export default Tags

export const query = graphql`query($path: String!) {
  taxonomyTermTags(path: { alias: { eq: $path } }) {
    id
    name
  }
  allNodeBlogPost(
    filter: {
      relationships: {
        field_blog_tags: { elemMatch: { path: { alias: { eq: $path } } } }
      }
      status: { eq: true }
    }
    sort: { fields: created, order: DESC }
  ) {
    edges {
      node {
        id
        created
        title
        relationships {
          field_blog_tags {
            name
            path {
              alias
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        path {
          alias
        }
        body {
          value
          format
          processed
          summary
        }
      }
    }
  }
}`